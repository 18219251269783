import React from "react"
import PropTypes from "prop-types"

import "./index.scss"
import Headline from "../components/index/headline"

const Layout = ({ children }) => {
  return (
    <>
      <Headline />
      <main>{children}</main>
      <footer class="gothic" style={{
        backgroundColor: `#000080`,
        color: `#fff`,
        textAlign: `center`,
        fontSize: `12px`,
        padding: `5px 0`,
      }}>
        © {new Date().getFullYear()}, TenGen Inc.
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
