import React from "react"
import Styles from "./index.module.scss"

const colors = ['dodgerblue', 'sandybrown', 'darkcyan', 'teal', 'deeppink', 'unset']
const backgroundColor = 'unset' //colors[Math.floor(Math.random() * colors.length)]

const Headline = () => (
  <div className={Styles.headline}
    data-sal="fade" data-sal-delay="300" data-sal-duration="1000" data-sal-easing="ease-out">
    <div className={Styles.logo} style={{ backgroundColor: backgroundColor }}
         data-sal="fade" data-sal-delay="1000" data-sal-duration="1000" data-sal-easing="ease-out">
      <div className={Styles.row}><span>T</span><span>e</span><span>n</span></div>
      <div className={Styles.row}><span>G</span><span>e</span><span>n</span></div>
      <div className={Styles.row}><span
        style={{
          fontSize: `26px`,
          position: `absolute`,
          bottom: `10px`,
          right: `-8px`,
      }}>Inc.</span></div>
    </div>
  </div>
)
export default Headline
